import { Profile } from '@local/Types'

export const mapExperienceStepPropsToValues = (profile: Profile) => {
  const cvPdf = profile?.documents.find(
    (doc) => doc.type.toLocaleLowerCase() === 'cv'
  )

  return {
    linkedIn: profile?.personalInformation?.socialMedia?.find(
      (sm) => sm.type.toLocaleLowerCase() === 'linkedin'
    )?.url,
    cvPdf: cvPdf ? { id: cvPdf.id, name: cvPdf.name } : null,
    consent: profile?.consent,
  }
}
