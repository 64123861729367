export enum Routes {
  Start = 'mitt-trr/sok-nytt-jobb/matchningsprofil',
  CreateProfile = 'skapa-matchningsprofil',
  StegYrkesroll = 'valj-yrkesroll',
  StegVarOchHur = 'var-och-hur',
}

export enum PageKeys {
  Start = 'matchningsprofil',
  CreateProfile = 'skapa-matchningsprofil',
  StegYrkesroll = 'valj-yrkesroll',
  StegVarOchHur = 'var-och-hur',
}

export enum WizardQueryParams {
  Update = 'uppdatera',
  Edit = 'redigera',
}

// Note: the steps has to be in the correct order (decending) so that the useGetWizardStep hook works
export enum WizardStepPaths {
  Jobtitlar = 'jobtitlar',
  VarOchHur = 'var-och-hur',
  JobTypes = 'valj-typ-och-omfattning',
  Yrkererfarenhet = 'yrkeserfarenhet',
  Sammanfattning = 'sammanfattning',
}
