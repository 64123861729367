import React from 'react'
import { CvPdf } from '@local/Types'
import { useFormikContext } from 'formik'
import { CircularProgress, List, ListSubheader } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'
import { useGetCvListQuery } from '../../Api/cvApi'

import { CvListItem } from './CvRadioItem'

interface CvRadioListProps {
  toggleModal: () => void
}

export const CvList: React.FC<CvRadioListProps> = ({ toggleModal }) => {
  const { setFieldValue } = useFormikContext<ExperienceStepFormValues>()
  const { sub } = useAuthentication()
  const { data, isLoading } = useGetCvListQuery(sub)

  const handleChange = (cv: CvPdf) => {
    void setFieldValue('cvPdf', cv)
    toggleModal()
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <List dense>
      <ListSubheader>Dina uppladdade CV:n</ListSubheader>
      {data.map((cv) => (
        <CvListItem onChange={handleChange} cv={cv} key={cv.id} />
      ))}
    </List>
  )
}
