import React from 'react'
import { JobTitle } from '@local/Types'
import { useFormikContext } from 'formik'
import { Chip, Stack } from '@mui/material'

import { JobtitleStepFormValues } from '../../Types/formTypes'

export const EditJobtitlesSection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<JobtitleStepFormValues>()

  const handleRemoveJobtitle = (jobtitle: JobTitle) => {
    const newJobtitles = values.jobtitles.filter(
      (title) => !(title.id === jobtitle.id && title.name === jobtitle.name)
    )
    void setFieldValue('jobtitles', newJobtitles, false)
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {values.jobtitles.map((jobTitle, i) => (
        <Chip
          variant="outlined"
          translate="no"
          onDelete={() => handleRemoveJobtitle(jobTitle)}
          data-testid="jobtitle-chip"
          key={i}
          label={jobTitle.name}
        />
      ))}
    </Stack>
  )
}
