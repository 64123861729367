import React from 'react'
import { useGetProfileQuery } from '@local/Api/profileApi'
import { TutorialPage } from '@local/Features/TutorialPage'
import { hasNonCompletedSteps } from '@local/Utils/Helpers/profileValidator'
import { CircularProgress } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'

import { ProfileContent } from './Components/ProfileContent/ProfileContent'

const ProfilePage: React.FC = () => {
  const { sub } = useAuthentication()
  const { data: profile, isLoading } = useGetProfileQuery(sub, {
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) return <CircularProgress />

  if (hasNonCompletedSteps(profile)) {
    return <TutorialPage />
  } else {
    return <ProfileContent />
  }
}

export default ProfilePage
