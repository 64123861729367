import { ExperienceStepContent } from '@local/Features/ExperienceStep'
import { linkedInRegEx } from '@local/Utils/Helpers/regexes'
import { object, string, AnySchema, boolean } from 'yup'

import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'

export const experienceStepValidationSchema = (
  content: ExperienceStepContent
): AnySchema<ExperienceStepFormValues> =>
  object().shape({
    linkedIn: string().matches(linkedInRegEx, content.linkedIn.warning),
    cvPdf: object({
      name: string(),
      id: string(),
    })
      .nullable()
      .optional(),
    consent: boolean(),
  })
