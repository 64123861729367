import React from 'react'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'

import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'
import { ConsentSwitch } from '../../../../Components/ConsentSwitch/ConsentSwitch'

export const ConsentBlock: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceStepFormValues>()

  const { title, description } = usePickEpiContent().wizardExperience.consent

  const handleRadioChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    void setFieldValue('consent', checked)
  }

  return (
    <ConsentSwitch
      label={title}
      description={description}
      checked={values.consent}
      onChange={handleRadioChange}
    />
  )
}
