import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from '@local/Utils/getConfig'

import { prepareHeaders } from './prepareHeaders'

const { PUBLIC_WEB_API_URL } = getConfig()

// initialize an empty api service that we'll inject endpoints into later as needed
export const socialMediaApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${PUBLIC_WEB_API_URL}`,
    prepareHeaders,
  }),
  reducerPath: 'socialMediaApi',
  tagTypes: [],
  endpoints: (builder) => ({
    putLinkedIn: builder.mutation<void, string>({
      query: (value) => ({
        url: `/user/social-media`,
        method: 'PUT',
        body: value ? [{ url: value, type: 'linkedin' }] : [],
      }),
    }),
  }),
})

export const { usePutLinkedInMutation } = socialMediaApi
