import React from 'react'
import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteProps,
  TextField,
} from '@mui/material'

export interface WrappedAutoCompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
    AutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  renderInput?: AutocompleteProps<
    T | string,
    Multiple,
    DisableClearable,
    FreeSolo
  >['renderInput']
}

export const CustomAutoComplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  ...props
}: WrappedAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
  <Autocomplete
    renderInput={(params) => <TextField {...params} />}
    sx={{
      [`& .${autocompleteClasses.popupIndicator}`]: {
        transform: 'none',
      },
    }}
    {...props}
  />
)
