import {
  JobTitle,
  LocationInformation,
  FormsOfEmployment,
  ExtentsOfEmployment,
  OtherPreferences,
  PatchCvPdf,
} from '@local/Types'

export enum PatchOps {
  Replace = 'replace',
}

export enum PatchPaths {
  Jobtitles = 'jobTitles',
  WorkPlaceType = 'additionalInformation/otherPreferences',
  Location = 'locationinformations',
  EmploymentForm = 'additionalInformation/formsOfEmployment',
  EmploymentExtent = 'additionalInformation/extentsOfEmployment',
  CompletedWizard = 'completedWizard',
  Cv = 'documents',
}

export interface IPatchBodyApiModel<T> {
  op: PatchOps
  path: PatchPaths
  value: T
}

export interface IPatchApiInput {
  jobtitles: IPatchBodyApiModel<JobTitle[]>
  locationinformations: IPatchBodyApiModel<LocationInformation[]>
  formsOfEmployment: IPatchBodyApiModel<FormsOfEmployment[]>
  extentsOfEmployment: IPatchBodyApiModel<ExtentsOfEmployment[]>
  otherPreferences: IPatchBodyApiModel<OtherPreferences[]>
  socialMedia: IPatchBodyApiModel<string[]>
  cv: IPatchBodyApiModel<PatchCvPdf[]>
  completedWizard: IPatchBodyApiModel<boolean>
}
