import React, { useState } from 'react'
import { AutocompleteChangeDetails, TextField, Typography } from '@mui/material'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { JobTitle } from '@local/Types'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { SearchRounded } from '@mui/icons-material'
import { CustomAutoComplete } from '@local/Components/CustomAutocomplete/CustomAutocomplete'
import parse from 'html-react-parser'

import { JobtitleStepFormValues } from '../../Types/formTypes'
import { useLazyGetJobtitlesQuery } from '../../Api/jobtitleApi'

export const SearchJobs: React.FC = () => {
  const { title, label } = usePickEpiContent().wizardJobtitles.searchJobtitles

  const [getJobtitles, { data: jobtitles, isFetching, isLoading }] =
    useLazyGetJobtitlesQuery()

  const { values, setFieldValue, setErrors, touched, errors } =
    useFormikContext<JobtitleStepFormValues>()

  const [input, setInput] = useState('')

  const filteredJobtitles = jobtitles?.filter(
    (jobtitle) => !values.jobtitles.some((j) => j.name === jobtitle.name)
  )

  const checkEquality = (option: JobTitle, value: JobTitle) =>
    option.id === value.id

  const handleAddJobtitle = (
    _event: React.SyntheticEvent<Element, Event>,
    _value: JobTitle[],
    _reason: string,
    details: AutocompleteChangeDetails<JobTitle>
  ) => {
    const addedJobtitle = details.option
    void setFieldValue('jobtitles', [addedJobtitle, ...values.jobtitles], false)
  }

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setErrors({ jobtitles: [] })
    fetchDebounce(value)
    setInput(value)
  }

  const fetchDebounce = debounce((searchTerm: string) => {
    if (!searchTerm) return
    void getJobtitles(searchTerm)
  }, 300)

  return (
    <>
      <Typography variant="body1" fontWeight={'bold'} marginBottom={1.5}>
        {title}
      </Typography>
      <CustomAutoComplete
        multiple
        options={!isFetching ? filteredJobtitles ?? [] : []}
        loading={isLoading || isFetching}
        loadingText="Laddar..."
        getOptionLabel={(jobtitle: JobTitle) => jobtitle.name}
        renderOption={(props, jobtitle: JobTitle) => (
          <li {...props} key={props.id} translate="no">
            {parse(jobtitle.nameWithHighLightedSearchTerm)}
          </li>
        )}
        autoHighlight
        inputValue={input}
        onChange={handleAddJobtitle}
        onInputChange={handleInputChange}
        noOptionsText={'Inga resultat'}
        open={input !== ''}
        popupIcon={<SearchRounded />}
        forcePopupIcon
        renderTags={() => null}
        isOptionEqualToValue={checkEquality}
        renderInput={(params) => (
          <TextField
            name="jobtitle"
            error={errors.jobtitles?.length > 0}
            helperText={generateErrorMessage({
              touched: touched.jobtitles !== undefined,
              errorMsg:
                typeof errors?.jobtitles === 'string'
                  ? errors.jobtitles
                  : undefined,
            })}
            FormHelperTextProps={{ id: 'jobtitle-input-error' }}
            {...params}
            placeholder={label}
          />
        )}
      />
    </>
  )
}
