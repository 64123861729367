import { baseApi } from '@local/Api/baseApi'
import { mapProfileToPatchModel } from '@local/Api/Helpers/mapPatchValues'
import { IPatchApiInput } from '@local/Types/patchTypes'

export const patchProfileApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    patchProfile: builder.mutation<
      void,
      { profile: Partial<IPatchApiInput>; clientId: string }
    >({
      query: ({ profile, clientId }) => ({
        url: `/client-match/profiles/${clientId}`,
        method: 'PATCH',
        body: mapProfileToPatchModel(profile),
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
})

export const { usePatchProfileMutation } = patchProfileApi
