import { MatchProfileContent } from '@local/Types'

export const fallbackContent: MatchProfileContent = {
  profile: {
    scrollToProfileLink: {
      title: 'scrollToProfileLink.title',
    },
    puffTitleIngress: {
      title: 'puffTitleIngress.title',
      ingress: 'puffTitleIngress.ingress',
    },
    jobPuff: {
      title: 'jobPuff.title',
      titleSingular: 'jobPuff.titleSingular',
      body: 'jobPuff.body',
      bodyAlternative: 'jobPuff.bodyAlternative',
    },
    activityPuff: {
      title: 'activityPuff.title',
      titleSingular: 'activityPuff.titleSingular',
      body: 'activityPuff.body',
      bodyAlternative: 'activityPuff.bodyAlternative',
    },
    titleIngress: {
      headingTitle: 'titleIngress.headingTitle',
      title: 'titleIngress.title',
    },
    jobtitles: {
      url: 'jobtitles.url',
      title: 'jobtitles.title',
      linkLabel: 'jobtitles.linkLabel',
      chipJobtitle: 'jobtitles.chipJobtitle',
    },
    locations: {
      url: 'locations.url',
      title: 'locations.title',
      linkLabel: 'locations.linkLabel',
      chipWorkplaceType: 'locations.chipWorkplaceType',
      chipLocation: 'locations.chipLocation',
      chipCountry: 'locations.chipCountry',
    },
    competences: {
      title: 'competences.title',
      linkLabel: 'competences.linkLabel',
    },
    employmentExtent: {
      url: 'employmentExtent.url',
      title: 'employmentExtent.title',
      linkLabel: 'employmentExtent.linkLabel',
      chipEmploymentType: 'employmentExtent.chipEmploymentType',
      chipEmploymentExtent: 'employmentExtent.chipEmploymentExtent',
    },
    experiences: {
      url: 'experiences.url',
      title: 'experiences.title',
      linkLabel: 'experiences.linkLabel',
      chipLinkedin: 'experiences.chipLinkedin',
      chipCv: 'experiences.chipCv',
    },
    consent: {
      heading: 'consent.heading',
      title: 'consent.title',
      description: 'consent.description',
    },
    contactInfo: {
      title: 'contactInfo.title',
      phone: 'contactInfo.phone',
      email: 'contactInfo.email',
    },
    editContactInfo: {
      url: 'editContactInfo.url',
      text: 'editContactInfo.text',
      linkLabel: 'editContactInfo.linkLabel',
    },
    heading: {
      title: 'heading.title',
    },
    suggestionsBlock: {
      header: 'suggestionsBlock.header',
      headerSingle: 'suggestionsBlock.headerSingle',
      subheader: 'suggestionsBlock.subheader',
      suggestionsHeader: 'suggestionsBlock.suggestionsHeader',
      submitLabel: 'suggestionsBlock.submitLabel',
      successMessage: 'suggestionsBlock.successMessage',
    },
  },
  communicationsBox: {
    title: {
      title: 'title.title',
      severity: 'info',
    },
    text: {
      mainBody: 'communicationsBox.text.mainBody',
    },
  },
  wizardIntro: {
    titleIngress: {
      subtitle: 'titleIngress.subtitle',
      titleContinue: 'titleIngress.titleContinue',
      ingressContinue: 'titleIngress.ingressContinue',
      headingTitle: 'titleIngress.headingTitle',
      titleOldProfile: 'titleIngress.titleOldProfile',
      ingressOldProfile: 'titleIngress.ingressOldProfile',
      title: 'titleIngress.title',
      ingress: 'titleIngress.ingress',
    },
    steps: {
      competences: 'steps.competences',
      jobtitles: 'steps.jobtitles',
      locations: 'steps.locations',
      typeOfEmployment: 'steps.typeOfEmployment',
      experience: 'steps.experience',
      consent: 'steps.consent',
    },
    guideButton: {
      create: 'guideButton.create',
      continue: 'guideButton.continue',
      url: 'guideButton.url',
      oldProfile: 'guideButton.oldProfile',
    },
  },
  wizardJobtitles: {
    stepGuide: {
      currentStepTitle: 'stepGuide.currentStepTitle',
    },
    searchJobtitles: {
      title: 'searchJobtitles.title',
      label: 'searchJobtitles.label',
      warning: 'searchJobtitles.warning',
      addCustomJobtitleFormat: 'searchJobtitles.addCustomJobtitleFormat',
    },
    jobtitleSection: {
      title: 'jobtitleSection.title',
      warning: 'jobtitleSection.warning',
    },
  },
  wizardVarOchHur: {
    stepGuide: {
      currentStepTitle: 'stepGuide.currentStepTitle',
    },
    workplaceType: {
      heading: 'workplaceType.heading',
      onsite: 'workplaceType.onsite',
      online: 'workplaceType.online',
      hybrid: 'workplaceType.hybrid',
    },
    locationPicker: {
      heading: 'locationPicker.heading',
      placeholder: 'locationPicker.placeholder',
      city: 'locationPicker.city',
      municipality: 'locationPicker.municipality',
      county: 'locationPicker.county',
      country: 'locationPicker.country',
      warning: 'locationPicker.warning',
    },
    foreignWorkLocation: {
      heading: 'foreignWorkLocation.heading',
      preamble: 'foreignWorkLocation.preamble',
      danmarkOption: 'foreignWorkLocation.danmarkOption',
      norgeOption: 'foreignWorkLocation.norgeOption',
    },
  },
  wizardJobType: {
    stepGuide: {
      currentStepTitle: 'stepGuide.currentStepTitle',
    },
    jobType: {
      heading: 'jobType.heading',
      tillsvidareOption: 'jobType.tillsvidareOption',
      tidsbegransadOption: 'jobType.tidsbegransadOption',
      konsultaSomAnstalldOption: 'jobType.konsultaSomAnstalldOption',
      konsultaSomForetagOption: 'jobType.konsultaSomForetagOption',
      warning: 'jobType.warning',
    },
    jobPartOrFullTime: {
      heading: 'jobPartOrFullTime.heading',
      heltidOption: 'jobPartOrFullTime.heltidOption',
      deltidOption: 'jobPartOrFullTime.deltidOption',
      warning: 'jobPartOrFullTime.warning',
    },
  },
  wizardCompetence: {
    searchCompetence: {
      warningMinCount: 'searchCompetences.warningMinCount',
      placeholder: 'searchCompetences.placeholder',
    },
    competenceSuggestions: {
      title: 'competenceSuggestions.title',
    },
    stepGuide: {
      currentStepTitle: 'stepGuide.currentStepTitle',
    },
  },
  wizardExperience: {
    stepGuide: {
      currentStepTitle: 'stepGuide.currentStepTitle',
    },
    text: {
      ingress: 'text.ingress',
    },
    linkedIn: {
      title: 'linkedIn.title',
      label: 'linkedIn.label',
      placeholder: 'linkedIn.placeholder',
      warning: 'linkedIn.warning',
    },
    cv: {
      title: 'cv.title',
      button: 'cv.button',
      modalTitle: 'cv.modalTitle',
      modalText: 'cv.modalText',
      modalButtonFromTool: 'cv.modalButtonFromTool',
      modalButtonUpload: 'cv.modalButtonUpload',
      modalFromToolTitle: 'cv.modalFromToolTitle',
      warning: 'cv.warning',
      errorFileSize: 'cv.errorFileSize',
      errorFileExtension: 'cv.errorFileExtension',
    },
    consent: {
      title: 'consent.title',
      heading: 'consent.heading',
      description: 'consent.description',
    },
    buttons: {
      save: 'buttons.save',
      create: 'buttons.create',
    },
    card: {
      title: 'card.title',
      body: 'card.body',
    },
  },
  PhotoTextBlock: {
    heading: 'PhotoTextBlock.heading',
    body: 'PhotoTextBlock.body',
  },
}
