import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const useFeatureFlag = () => {
  const testFlagIsEnabled = useIsFeatureEnabled('testFlag')

  const isGtmTrackingEnabled = useIsFeatureEnabled(
    'gtm.enabled.matching-profil-v2'
  )
  const isCleanProfileButtonEnabled = useIsFeatureEnabled(
    'matchProfile.cleanProfileButton'
  )
  const isCommunicationBoxEnabled = useIsFeatureEnabled(
    'matchprofile.communicationsBox'
  )

  return {
    testFlagIsEnabled,
    isGtmTrackingEnabled,
    isCleanProfileButtonEnabled,
    isCommunicationBoxEnabled,
  }
}

export default useFeatureFlag
