import React from 'react'
import { withFormik } from 'formik'

import { mapExperienceStepPropsToValues } from '../../Helpers/experienceStepForm.helpers'
import { experienceStepValidationSchema } from '../../Components/ExperienceStepForm/experienceStepForm.schema'
import {
  ExperienceStepFormProps,
  ExperienceStepFormValues,
} from '../../Types/experienceStepFormTypes'
import { ExperienceStep } from '../ExperienceStep/ExperienceStep'

const ExperienceStepForm: React.FC = () => <ExperienceStep />

export const ExperienceStepFormik = withFormik<
  ExperienceStepFormProps,
  ExperienceStepFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ profile }) => mapExperienceStepPropsToValues(profile),
  validationSchema: ({ content }: ExperienceStepFormProps) =>
    experienceStepValidationSchema(content),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  displayName: 'ExperienceStepForm',
})(ExperienceStepForm)
