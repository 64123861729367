import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Routes, WizardStepPaths } from '@local/Router/routes'
import { JobtitleStep } from '@local/Features/JobtitlesStep'
import { JobLocationStep } from '@local/Features/JobLocationStep'
import { Wizard } from '@trr/wizard-library'
import { History, Location } from 'history'
import { useHistory, useLocation } from 'react-router-dom'
import { JobTypeStep } from '@local/Features/JobTypeStep'
import { ExperienceStep } from '@local/Features/ExperienceStep'
import { Content } from '@local/Components/Content/Content'
import { CircularProgress } from '@mui/material'

export const CreateProfileWizard: React.FC = () => {
  const [startStep, setStartStep] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoadingParams, setIsLoadingParams] = useState(true)
  const history = useHistory() as History
  const { pathname } = useLocation() as Location

  const getWizardStepFromPath = (path: WizardStepPaths): WizardStepPaths => {
    if (
      Object.values(WizardStepPaths).some((stepPath) =>
        history.location.pathname.includes(stepPath)
      )
    ) {
      const pathParts = path?.split('/')
      // if history. ends with a / then the last part will be empty, so pop it twice to get the step
      const stepPart = pathParts?.pop() || pathParts?.pop()
      return stepPart as WizardStepPaths
    } else {
      history.replace(
        `/${Routes.Start}/${Routes.CreateProfile}/${WizardStepPaths.Jobtitlar}`
      )
      return WizardStepPaths.Jobtitlar
    }
  }

  const getWizardStepIndexFromPath = useCallback((path: WizardStepPaths) => {
    const pathStep = getWizardStepFromPath(path)
    switch (pathStep) {
      case WizardStepPaths.Jobtitlar:
        return 0
      case WizardStepPaths.VarOchHur:
        return 1
      case WizardStepPaths.JobTypes:
        return 2
      case WizardStepPaths.Yrkererfarenhet:
        return 3
      case WizardStepPaths.Sammanfattning:
        return 4
      default:
        return 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    const startStepFromPath = getWizardStepIndexFromPath(
      history.location.pathname as WizardStepPaths
    )
    setStartStep(startStepFromPath)
    setCurrentStep(startStepFromPath)
    setIsLoadingParams(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pathname?.match(/mitt-trr\/sok-nytt-jobb\/matchningsprofil\/?$/gm)) {
      return
    }
    const newStep = getWizardStepIndexFromPath(pathname as WizardStepPaths)
    setCurrentStep(newStep)
  }, [getWizardStepIndexFromPath, pathname])

  if (isLoadingParams) {
    return <CircularProgress />
  }

  return (
    <Content>
      <Wizard startIndex={startStep} currentIndex={currentStep}>
        <JobtitleStep />
        <JobLocationStep />
        <JobTypeStep />
        <ExperienceStep />
      </Wizard>
    </Content>
  )
}
