import { object, array, string, AnySchema } from 'yup'

import { JobtitlesStepContent } from '../../Types/content'
import { JobtitleStepFormValues } from '../../Types/formTypes'

export const jobttitleStepValidationSchema = (
  content: JobtitlesStepContent
): AnySchema<JobtitleStepFormValues> =>
  object().shape({
    jobtitles: array()
      .of(
        object().shape({
          id: string().required(),
          name: string().required(),
        })
      )
      .min(1, content.searchJobtitles.warning),
  })
