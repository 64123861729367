import React from 'react'
import { FromProfileProvider } from '@local/Utils/Hooks/useFromProfile'
import { TitleAndBody } from '@local/Components/TitleAndBody/TitleAndBody'
import { ProfilePuffs } from '@local/Features/MatchPuffs'
import ArticleImageCard from '@local/Components/ArticleImageCard/ArticleImageCard'
import { Content } from '@local/Components/Content/Content'
import { Button, Divider, Stack } from '@mui/material'
import { PatchOps, PatchPaths } from '@local/Types/patchTypes'
import { useFeatureFlag, usePickEpiContent } from '@local/Utils/Hooks'
import { useAuthentication } from '@trr/app-shell-data'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import useGtmProfilePageTracker from '@local/Utils/Hooks/useGtmPageTracker'

import JobTitlesBlock from '../JobTitlesBlock/JobTitlesBlock'
import { CommunicationsBoxSimple } from '../CommunicationsBoxSimple/CommunicationsBoxSimple'
import LocationsBlock from '../LocationsBlock/LocationsBlock'
import TypeOfEmploymentBlock from '../TypeOfEmploymentBlock/TypeOfEmploymentBlock'
import ResumeBlock from '../ResumeBlock/ResumeBlock'
import ContactInformationBlock from '../ContactInformationBlock/ContactInformationBlock'
import { ConsentBlock } from '../ConsentBlock/ConsentBlock'

export const ProfileContent = () => {
  const { title, ingress } = usePickEpiContent().profile.puffTitleIngress
  const { PhotoTextBlock } = usePickEpiContent()
  const { isCleanProfileButtonEnabled } = useFeatureFlag()
  const { isCommunicationBoxEnabled } = useFeatureFlag()
  const { sub } = useAuthentication()
  const [patchProfile] = usePatchProfileMutation()

  useGtmProfilePageTracker(true)

  const handleDeleteEverything = () => {
    void patchProfile({
      profile: {
        jobtitles: {
          op: PatchOps.Replace,
          path: PatchPaths.Jobtitles,
          value: [],
        },
        locationinformations: {
          op: PatchOps.Replace,
          path: PatchPaths.Location,
          value: [],
        },
        formsOfEmployment: {
          op: PatchOps.Replace,
          path: PatchPaths.EmploymentForm,
          value: [],
        },
        extentsOfEmployment: {
          op: PatchOps.Replace,
          path: PatchPaths.EmploymentExtent,
          value: [],
        },
        otherPreferences: {
          op: PatchOps.Replace,
          path: PatchPaths.WorkPlaceType,
          value: [],
        },
        cv: {
          op: PatchOps.Replace,
          path: PatchPaths.Cv,
          value: [],
        },
        completedWizard: {
          op: PatchOps.Replace,
          path: PatchPaths.CompletedWizard,
          value: false,
        },
      },
      clientId: sub,
    })
  }

  return (
    <FromProfileProvider value>
      <Content>
        <Stack spacing={4}>
          <TitleAndBody
            title={title}
            body={ingress}
            data-testid="profile-title"
          />
          {isCommunicationBoxEnabled && <CommunicationsBoxSimple />}
          <ProfilePuffs />
          <Stack
            spacing={3}
            id="profile-section"
            data-testid="profile-section"
            divider={<Divider />}
          >
            <JobTitlesBlock />
            <LocationsBlock />
            <TypeOfEmploymentBlock />
            <Stack spacing={3}>
              <ResumeBlock />
              <ContactInformationBlock />
              <ConsentBlock />
            </Stack>
            {isCleanProfileButtonEnabled && (
              <Button
                onClick={handleDeleteEverything}
                sx={{ mt: 3, bgcolor: 'red' }}
              >
                Ta bort ALLT
              </Button>
            )}
          </Stack>
          <ArticleImageCard
            image={PhotoTextBlock.image}
            title={PhotoTextBlock.heading}
            description={PhotoTextBlock.body}
            linkText={PhotoTextBlock.button.text}
            link={PhotoTextBlock.button.url}
          />
        </Stack>
      </Content>
    </FromProfileProvider>
  )
}
