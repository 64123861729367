import React from 'react'
import { useLazyGetCvQuery } from '@local/Features/ExperienceStep'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useGetWizardStep } from '@local/Utils/Hooks/wizard'
import { WizardStepPaths } from '@local/Router/routes'
import { GUID } from '@local/Types'
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
} from '@mui/material'
import { Download, OpenInNew } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetProfileQuery } from '@local/Api/profileApi'

import ProfileSection from '../ProfileSection/ProfileSection'

const DownloadCell = styled(TableCell)({
  paddingLeft: 0,
  paddingRight: 0,
  verticalAlign: 'top',
})

const LabelCell = ({ label, value }: { label: string; value: string }) => (
  <TableCell
    sx={{
      paddingLeft: 0,
      paddingRight: 0,
      whiteSpace: { xs: 'unset', sm: 'nowrap' },
      wordBreak: 'break-word',
      overflow: 'hidden',
      maxWidth: '300px',
      textOverflow: 'ellipsis',
    }}
  >
    <Box
      component="span"
      sx={{
        width: 200,
        display: { xs: 'block', sm: 'inline-block' },
      }}
    >
      {label}
    </Box>
    <span>{value}</span>
  </TableCell>
)

const ResumeBlock: React.FC = () => {
  const { title, linkLabel, chipLinkedin, chipCv } =
    usePickEpiContent().profile.experiences
  const { getWizardUrlStep } = useGetWizardStep()
  const [getCv] = useLazyGetCvQuery()
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)

  const handleDownloadCv = (id: GUID, name: string) => {
    void getCv({ cv: { pdfId: id, name: name }, clientId: sub })
  }

  const getLinkedInData = () =>
    profile?.personalInformation?.socialMedia.find(
      (link) => link.type === 'linkedin'
    )

  const getCvData = () =>
    profile?.documents.length > 0 ? profile?.documents[0] : null
  const linkedInData = getLinkedInData()
  const cvData = getCvData()

  return (
    <ProfileSection
      title={title}
      changeButtonProps={{
        title: linkLabel,
        href: getWizardUrlStep(WizardStepPaths.Yrkererfarenhet, true),
      }}
      data-testid="document-block"
    >
      <Table>
        <TableBody>
          {cvData && (
            <TableRow>
              <LabelCell label={chipCv} value={cvData.name} />
              <DownloadCell align="right">
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  size="small"
                  data-gtm-label="Ladda ner CV"
                  onClick={() => handleDownloadCv(cvData.id, cvData.name)}
                  endIcon={<Download />}
                >
                  Ladda ner
                </Button>
              </DownloadCell>
            </TableRow>
          )}
          {linkedInData && (
            <TableRow>
              <LabelCell label={chipLinkedin} value={linkedInData.url} />
              <DownloadCell align="right">
                <Button
                  component={Link}
                  variant="text"
                  size="small"
                  endIcon={<OpenInNew />}
                  to={{ pathname: linkedInData.url }}
                  target="_blank"
                  data-trackable="false" // prevent sending personal information to GA
                  data-testid="resumeblock-linkedin-link"
                >
                  Visa
                </Button>
              </DownloadCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!cvData && !linkedInData && <Divider />}
    </ProfileSection>
  )
}

export default ResumeBlock
