import { baseApi } from '@local/Api/baseApi'
import { Profile } from '@local/Types'

export const profileApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, string>({
      query: (clientId) => `/client-match/profiles/${clientId}`,
      providesTags: ['Profile'],
    }),
  }),
})

export const { useGetProfileQuery } = profileApi
