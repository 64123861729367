import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { OtherPreferences } from '@local/Types'
import { PatchOps } from '@local/Types/patchTypes'
import { useWizard } from '@trr/wizard-library'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import React from 'react'
import { isEmpty } from 'lodash'
import { InputGroup } from '@local/Components/InputGroup/InputGroup'
import { ProfileWizardStep } from '@local/Components/ProfileWizardStep/ProfileWizardStep'
import { isEditingOrUpdating } from '@local/Utils/Helpers/form.helpers'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'
import { useNavigationGtmTracker } from '@local/Utils/Hooks/gtm'

import { LocationCheckbox } from '../LocationCheckbox/LocationCheckbox'
import {
  JobLocationStepFormValues,
  JobLocationFormTypes,
} from '../../Types/formTypes'
import { SearchLocations } from '../SearchLocations/SearchLocations'

export const JobLocationStep: React.FC = () => {
  const { stepCount, activeStep } = useWizard()
  const { goToNextWizardStep, goToPreviousWizardStep, saveAndExit } =
    useWizardNavigation()
  const [patchProfile] = usePatchProfileMutation()
  const shouldGoBack = isEditingOrUpdating()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()
  const { stepGuide, workplaceType, locationPicker, foreignWorkLocation } =
    usePickEpiContent().wizardVarOchHur
  const { sub } = useAuthentication()

  const { values, setTouched, validateForm, setFieldValue } =
    useFormikContext<JobLocationStepFormValues>()

  const handlePrevious = () => {
    goToPreviousWizardStep()
  }

  const handleOtherPreferencesChange = (
    option: OtherPreferences,
    checked: boolean
  ) => {
    checked
      ? handleAddOtherPreferences(option)
      : handleRemoveOtherPreferences(option)
  }

  const handleAddOtherPreferences = (option: OtherPreferences) => {
    void setFieldValue(JobLocationFormTypes.OtherPreferences, [
      option,
      ...values.otherPreferences,
    ])
  }

  const handleRemoveOtherPreferences = (option: OtherPreferences) => {
    void setFieldValue(
      JobLocationFormTypes.OtherPreferences,
      values.otherPreferences.filter(
        (existingOption) => existingOption !== option
      )
    )
  }

  const handlePatchAndNext = () => {
    const otherPreferences: OtherPreferences[] = values.otherPreferences

    void patchProfile({
      body: [
        {
          op: PatchOps.Replace,
          path: 'locationinformations',
          value: [...values.locations, ...values.foreignLocations],
        },
        {
          op: PatchOps.Replace,
          path: 'additionalInformation/otherPreferences',
          value: otherPreferences,
        },
      ],
      clientId: sub,
    })
      .unwrap()
      .then(() => {
        if (shouldGoBack) {
          saveAndExit()
        } else {
          goToNextWizardStep()
        }
      })
  }

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          void setTouched(
            setNestedObjectValues<FormikTouched<JobLocationStepFormValues>>(
              validationErrors,
              true
            )
          )
          trackInvalidFormSubmit()
        } else {
          handlePatchAndNext()
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <ProfileWizardStep
      progressBar={{ max: stepCount, current: activeStep + 1 }}
      stepInfo={{
        currentStepLabel: stepGuide.currentStepTitle,
        nextStepLabel: stepGuide.nextStepTitle,
      }}
      nextButton={{ onClick: handleNext }}
      prevButton={{ onClick: handlePrevious }}
    >
      <Stack spacing={3}>
        <Box id="workplace-type">
          <Typography variant="body1" fontWeight={'bold'} gutterBottom>
            {workplaceType.heading}
          </Typography>
          <InputGroup>
            <FormControlLabel
              label={workplaceType.onsite}
              control={
                <Checkbox
                  onChange={(_, checked) =>
                    handleOtherPreferencesChange(
                      OtherPreferences.OnSite,
                      checked
                    )
                  }
                  checked={values.otherPreferences.includes(
                    OtherPreferences.OnSite
                  )}
                />
              }
            />
            <FormControlLabel
              label={workplaceType.online}
              control={
                <Checkbox
                  onChange={(_, checked) =>
                    handleOtherPreferencesChange(
                      OtherPreferences.RemoteWork,
                      checked
                    )
                  }
                  checked={values.otherPreferences.includes(
                    OtherPreferences.RemoteWork
                  )}
                />
              }
            />
            <FormControlLabel
              label={workplaceType.hybrid}
              control={
                <Checkbox
                  onChange={(_, checked) =>
                    handleOtherPreferencesChange(
                      OtherPreferences.Hybrid,
                      checked
                    )
                  }
                  checked={values.otherPreferences.includes(
                    OtherPreferences.Hybrid
                  )}
                />
              }
            />
          </InputGroup>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight={'bold'} gutterBottom>
            {locationPicker.heading}
          </Typography>
          <SearchLocations
            label={locationPicker.placeholder}
            name={JobLocationFormTypes.Locations}
          />
        </Box>
        <Box>
          <Typography variant="body1" fontWeight={'bold'} gutterBottom>
            {foreignWorkLocation.heading}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {foreignWorkLocation.preamble}
          </Typography>
          <InputGroup>
            <LocationCheckbox
              checked={values.foreignLocations.some(
                (option) => option.name === 'Danmark'
              )}
              searchTerm="Danmark"
              label={foreignWorkLocation.danmarkOption}
            />
            <LocationCheckbox
              checked={values.foreignLocations.some(
                (option) => option.name === 'Norge'
              )}
              searchTerm="Norge"
              label={foreignWorkLocation.norgeOption}
            />
          </InputGroup>
        </Box>
      </Stack>
    </ProfileWizardStep>
  )
}
