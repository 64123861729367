import { baseApi } from '@local/Api/baseApi'
import { GUID, CvPdf } from '@local/Types'
import { downloadFile } from '@local/Utils/Helpers/fileHelper'

export interface CvResponse {
  pdfId: string
  fileName: string
}

interface UploadCvResponse {
  pdfId: GUID
  userId: GUID
  fileName: string
  pdfType: number
  created: string
  createdAction: string
}

interface GetCvRequest {
  pdfId: GUID
  name: string
}

export const cvApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCvList: builder.query<CvPdf[], string>({
      query: (clientId) => ({
        url: `/api/cv/Pdf/GetByUserId/${clientId}`,
      }),
      transformResponse: (baseQueryReturnValue: CvResponse[]): CvPdf[] =>
        baseQueryReturnValue?.map((cv) => ({
          id: cv.pdfId,
          name: cv.fileName,
        })),
    }),
    postCv: builder.mutation<UploadCvResponse, FormData>({
      query: (cv) => ({
        url: `/api/cv/Pdf/Upload`,
        method: 'POST',
        body: cv,
      }),
    }),
    getCv: builder.query<void, { cv: GetCvRequest; clientId: string }>({
      queryFn: async (
        { cv: { pdfId, name }, clientId },
        api,
        extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/api/cv/Pdf/Get`,
          params: { userId: clientId, pdfId: pdfId },
          responseHandler: (response) => response.blob(),
        })

        downloadFile(result.data as Blob, name)
        return { data: null }
      },
    }),
  }),
})

export const { useGetCvListQuery, usePostCvMutation, useLazyGetCvQuery } = cvApi
