import React from 'react'
import { useGetProfileQuery } from '@local/Api/profileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { WizardStepPaths } from '@local/Router/routes'
import { NonCompletedWizardSteps } from '@local/Types'
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardProps,
} from '@mui/material'
import { WizardStepGuide } from '@trr/wizard-library'
import { difference } from 'ramda'
import { CheckRounded } from '@mui/icons-material'
import { useAuthentication } from '@trr/app-shell-data'

export const WizardIntro = ({ ...props }: CardProps) => {
  const { sub } = useAuthentication()
  const { data: profile } = useGetProfileQuery(sub)

  const { titleIngress, steps, guideButton } = usePickEpiContent().wizardIntro

  const completedSteps = difference(
    Object.values(NonCompletedWizardSteps),
    profile.nonCompletedWizardSteps
  )

  const hasStartedWizard = completedSteps.length > 0
  const hasOldProfile = () => !profile.completedWizard

  const getTitleText = () => {
    if (hasOldProfile()) {
      return titleIngress.titleOldProfile
    }

    return hasStartedWizard ? titleIngress.titleContinue : titleIngress.title
  }

  const getIngressText = () => {
    if (hasOldProfile()) {
      return titleIngress.ingressOldProfile
    }

    return hasStartedWizard
      ? titleIngress.ingressContinue
      : titleIngress.ingress
  }
  const getButtonText = () => {
    if (hasOldProfile()) {
      return guideButton.oldProfile
    }

    return hasStartedWizard ? guideButton.continue : guideButton.create
  }

  const stepFromCompleted = () => {
    const minStepNotFinished = profile.nonCompletedWizardSteps[0]

    switch (minStepNotFinished) {
      case NonCompletedWizardSteps.JobTitles:
        return WizardStepPaths.Jobtitlar
      case NonCompletedWizardSteps.Locations:
        return WizardStepPaths.VarOchHur
      case NonCompletedWizardSteps.Jobtypes:
        return WizardStepPaths.JobTypes
      case NonCompletedWizardSteps.Experience:
        return WizardStepPaths.Yrkererfarenhet
      default:
        return WizardStepPaths.Sammanfattning
    }
  }

  return (
    <Card data-testid="wizard-intro-container" {...props}>
      <CardContent sx={{ padding: '1.5rem 1.5rem 1rem' }}>
        <Typography variant="h5" mb={2}>
          {getTitleText()}
        </Typography>
        <Typography variant="body1" mb={1}>
          {getIngressText()}
        </Typography>
        <WizardStepGuide
          guideItems={[
            {
              text: steps.jobtitles,
              color: 'green',
              icon: completedSteps.includes(
                NonCompletedWizardSteps.JobTitles
              ) ? (
                <CheckRounded titleAccess="Steg 1 färdigt" />
              ) : undefined,
            },
            {
              text: steps.locations,
              color: 'green',
              icon: completedSteps.includes(
                NonCompletedWizardSteps.Locations
              ) ? (
                <CheckRounded titleAccess="Steg 2 färdigt" />
              ) : undefined,
            },
            {
              text: steps.typeOfEmployment,
              color: 'green',
              icon: completedSteps.includes(
                NonCompletedWizardSteps.Jobtypes
              ) ? (
                <CheckRounded titleAccess="Steg 3 färdigt" />
              ) : undefined,
            },
            {
              text: steps.experience,
              color: 'green',
            },
          ]}
        />
      </CardContent>

      <CardActions sx={{ padding: '0 1.5rem 2rem' }}>
        <Button
          color="primary"
          title={getButtonText()}
          href={`${guideButton.url}${stepFromCompleted()}`}
          data-testid="create-profile-button"
        >
          {getButtonText()}
        </Button>
      </CardActions>
    </Card>
  )
}
