import React from 'react'
import { CircularProgress } from '@mui/material'
import { useGetProfileQuery } from '@local/Api/profileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useAuthentication } from '@trr/app-shell-data'

import { ExperienceStepFormik } from './Components/ExperienceStepForm/ExperienceStepForm'

export const ExperienceStep: React.FC = () => {
  const { sub } = useAuthentication()
  const { data, isLoading } = useGetProfileQuery(sub)

  const { wizardExperience } = usePickEpiContent()

  if (isLoading) {
    return <CircularProgress />
  }

  return <ExperienceStepFormik profile={data} content={wizardExperience} />
}

export * from './Types/experienceStepContent'
export * from './Api/cvApi'
