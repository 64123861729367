import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { LocationInformation } from '@local/Types'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { CustomAutoComplete } from '@local/Components/CustomAutocomplete/CustomAutocomplete'
import { Chip, Stack, TextField } from '@mui/material'
import { SearchRounded } from '@mui/icons-material'
import parse from 'html-react-parser'

import { JobLocationStepFormValues } from '../../Types/formTypes'
import {
  GetLocationsResponse,
  useLazyGetLocationsQuery,
} from '../../Api/locationsApi'
import {
  filterAlreadyPickedLocations,
  filterCountries,
} from '../../Utils/formHelper'

interface SearchLocationProps {
  label: string
  name: string
}

export const SearchLocations = ({ label, name }: SearchLocationProps) => {
  const [getLocations, { data: locationInformations, isFetching, isLoading }] =
    useLazyGetLocationsQuery()
  const { values, touched, errors, setFieldValue, setErrors } =
    useFormikContext<JobLocationStepFormValues>()

  const [input, setInput] = useState('')

  const { locationPicker } = usePickEpiContent().wizardVarOchHur

  const handleAddLocation = (
    event: React.SyntheticEvent<Element, Event>,
    locations: LocationInformation[]
  ) => {
    void setFieldValue(name, [
      ...values.locations,
      ...locations.filter((l) => !values.locations.includes(l)),
    ])
  }

  const checkEquality = (
    option: LocationInformation,
    value: LocationInformation
  ) => option.name === value.name && option.id === value.id

  const handleRemoveLocation = (id: string) => {
    void setFieldValue(
      name,
      values.locations.filter((location) => location.id !== id)
    )
  }

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setErrors({ locations: [] })
    fetchDebounce(value)
    setInput(value)
  }

  const fetchDebounce = debounce((searchTerm: string) => {
    void getLocations(searchTerm)
  }, 300)

  const filteredLocations: LocationInformation[] = filterAlreadyPickedLocations(
    filterCountries(locationInformations),
    values.locations
  )

  return (
    <Stack spacing={1}>
      <CustomAutoComplete
        multiple
        options={!isFetching ? filteredLocations ?? [] : []}
        loading={isLoading || isFetching}
        loadingText="Laddar..."
        getOptionLabel={(location: GetLocationsResponse) => location.name}
        renderOption={(props, location: GetLocationsResponse) => (
          <li {...props} key={location.id} translate="no">
            {parse(location.nameWithHighLightedSearchTerm)}
            &nbsp;{locationPicker[location.type]}
          </li>
        )}
        inputValue={input}
        autoHighlight
        onChange={handleAddLocation}
        onInputChange={handleInputChange}
        isOptionEqualToValue={checkEquality}
        noOptionsText={'Inga resultat'}
        open={input !== ''}
        popupIcon={<SearchRounded />}
        forcePopupIcon
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            name="locations"
            error={
              errors.locations?.length > 0 && touched.locations !== undefined
            }
            helperText={generateErrorMessage({
              touched: touched.locations !== undefined,
              errorMsg: errors.locations as string,
            })}
            FormHelperTextProps={{ id: 'locations-input-error' }}
            {...params}
            placeholder={label}
          />
        )}
      />
      <Stack direction="row" gap={1} flexWrap="wrap">
        {values.locations?.map((location: LocationInformation) => (
          <Chip
            translate="no"
            variant="outlined"
            onDelete={() => handleRemoveLocation(location.id)}
            key={location.id}
            data-testid="joblocation-chip"
            label={`${location.name} ${locationPicker[location.type]}`}
          />
        ))}
      </Stack>
    </Stack>
  )
}
