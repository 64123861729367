import React from 'react'
import { useWizard } from '@trr/wizard-library'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import { PatchOps, PatchPaths } from '@local/Types/patchTypes'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { isEmpty } from 'ramda'
import { ProfileWizardStep } from '@local/Components/ProfileWizardStep/ProfileWizardStep'
import { usePutLinkedInMutation } from '@local/Api/socialMediaApi'
import { isEditingOrUpdating } from '@local/Utils/Helpers/form.helpers'
import { Box, Stack, Typography } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'
import {
  useNavigationGtmTracker,
  useClickGtmTracker,
} from '@local/Utils/Hooks/gtm'
import { usePutConsentMutation } from '@local/Api/consentApi'

import { CvBlock } from '../CvBlock/CvBlock'
import { ExperienceStepFormValues } from '../../Types/experienceStepFormTypes'
import { LinkedInBlock } from '../LinkedInBlock/LinkedInBlock'
import { ConsentBlock } from '../ConsentBlock/ConsentBlock'
import { ExperienceStepCard } from '../ExperienceStepCard/ExperienceStepCard'

export const ExperienceStep: React.FC = () => {
  const { stepCount, activeStep } = useWizard()
  const { goToProfilePage, goToPreviousWizardStep, saveAndExit } =
    useWizardNavigation()

  const { stepGuide, text, buttons } = usePickEpiContent().wizardExperience
  const [patchProfile] = usePatchProfileMutation()
  const [putLinkedIn] = usePutLinkedInMutation()
  const [putConsent] = usePutConsentMutation()

  const { values, setTouched, validateForm } =
    useFormikContext<ExperienceStepFormValues>()
  const isEditing = isEditingOrUpdating()
  const { trackButtonClick } = useClickGtmTracker()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()
  const { sub } = useAuthentication()

  const handlePatchAndNext = async () => {
    await putLinkedIn(values.linkedIn).unwrap()
    await patchProfile({
      profile: {
        cv: {
          op: PatchOps.Replace,
          path: PatchPaths.Cv,
          value: values.cvPdf ? [{ ...values.cvPdf, type: 'Cv' }] : null,
        },
      },
      clientId: sub,
    }).unwrap()
    await putConsent(values.consent).unwrap()

    if (isEditing) {
      saveAndExit()
    } else {
      goToProfilePage('Skapa profil')
    }
  }

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          void setTouched(
            setNestedObjectValues<FormikTouched<ExperienceStepFormValues>>(
              validationErrors,
              true
            )
          )
          trackInvalidFormSubmit()
        } else {
          trackButtonClick('Linkedinprofil', {
            label: values.linkedIn?.length > 0 ? 'Angiven' : 'Ej angiven',
          })
          void handlePatchAndNext()
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <ProfileWizardStep
      progressBar={{ max: stepCount, current: activeStep + 1 }}
      stepInfo={
        isEditing ? { currentStepLabel: stepGuide.currentStepTitle } : undefined
      }
      nextButton={{
        onClick: handleNext,
        text: isEditing ? buttons.save : buttons.create,
      }}
      prevButton={{ onClick: goToPreviousWizardStep }}
    >
      <Stack spacing={3} mt={isEditing ? 0 : 3}>
        {!isEditing && <ExperienceStepCard />}
        <Box>
          {!isEditing && (
            <Typography variant="h4" gutterBottom>
              {stepGuide.currentStepTitle}
            </Typography>
          )}
          <Typography variant="body1">{text.ingress}</Typography>
        </Box>

        <LinkedInBlock />
        <CvBlock />
        <ConsentBlock />
      </Stack>
    </ProfileWizardStep>
  )
}
