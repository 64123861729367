import { IPatchApiInput, IPatchBodyApiModel } from '@local/Types/patchTypes'

export const mapProfileToPatchModel = (
  patchInput: Partial<IPatchApiInput>
): IPatchBodyApiModel<unknown>[] =>
  Object.keys(patchInput).map((key: keyof IPatchApiInput) => ({
    op: patchInput[key].op,
    path: patchInput[key].path,
    value: patchInput[key].value,
  }))
