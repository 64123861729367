import React from 'react'
import { SuggestionBox } from '@local/Components/SuggestionBox/SuggestionBox'
import { useGetProfileSuggestionsQuery } from '@local/Api/profileApi'
import { useAuthentication } from '@trr/app-shell-data'
import { useCompetenceForm } from '@local/Features/WizardSteps/CompetenceStep/Hooks/useCompetenceForm'
import { usePickEpiContent } from '@local/Utils/Hooks'

export const CompetenceSuggestions: React.FC = () => {
  const { sub } = useAuthentication()
  const { data: suggestions } = useGetProfileSuggestionsQuery(sub)
  const { title } = usePickEpiContent().wizardCompetence.competenceSuggestions

  const { toggleCompetence, formikCompetences } = useCompetenceForm()

  const handleOnClick = (suggestion: string) => {
    toggleCompetence(suggestions?.competences.find((s) => s === suggestion))
  }

  if (!suggestions?.competences?.length) return null

  return (
    <SuggestionBox
      title={title}
      suggestions={suggestions?.competences}
      selected={formikCompetences}
      onClick={handleOnClick}
    />
  )
}
