import { ProfileWizardStep } from '@local/Components/ProfileWizardStep/ProfileWizardStep'
import { usePatchProfileMutation } from '@local/Api/patchProfileApi'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { useWizardNavigation } from '@local/Utils/Hooks/wizard'
import { PatchOps, PatchPaths } from '@local/Types/patchTypes'
import { useWizard } from '@trr/wizard-library'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { isEmpty } from 'ramda'
import React from 'react'
import { isEditingOrUpdating } from '@local/Utils/Helpers/form.helpers'
import { useAuthentication } from '@trr/app-shell-data'
import { useNavigationGtmTracker } from '@local/Utils/Hooks/gtm'
import { Stack } from '@mui/material'

import { JobtitleStepFormValues } from '../../Types/formTypes'
import { EditJobtitlesSection } from '../EditJobtitlesSection/EditJobtitlesSection'
import { SearchJobs } from '../SearchJobs/SearchJobs'

export const JobtitlesStep: React.FC = () => {
  const { stepCount, activeStep } = useWizard()
  const { goToNextWizardStep, goToProfilePage, saveAndExit } =
    useWizardNavigation()
  const { currentStepTitle, nextStepTitle } =
    usePickEpiContent().wizardJobtitles.stepGuide
  const [patchProfile] = usePatchProfileMutation()
  const shouldGoBack = isEditingOrUpdating()
  const { sub } = useAuthentication()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()

  const { values, setTouched, validateForm } =
    useFormikContext<JobtitleStepFormValues>()

  const handlePatchAndNext = () => {
    void patchProfile({
      profile: {
        jobtitles: {
          op: PatchOps.Replace,
          path: PatchPaths.Jobtitles,
          value: values.jobtitles,
        },
      },
      clientId: sub,
    })
      .unwrap()
      .then(() => {
        if (shouldGoBack) {
          saveAndExit()
        } else {
          goToNextWizardStep()
        }
      })
  }

  const handleNext = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          void setTouched(
            setNestedObjectValues<FormikTouched<JobtitleStepFormValues>>(
              validationErrors,
              true
            )
          )
          trackInvalidFormSubmit()
        } else {
          handlePatchAndNext()
        }
      })
      .catch((e) => console.error(e))
  }

  return (
    <ProfileWizardStep
      progressBar={{ max: stepCount, current: activeStep + 1 }}
      stepInfo={{
        currentStepLabel: currentStepTitle,
        nextStepLabel: nextStepTitle,
      }}
      prevButton={{
        text: 'Avbryt',
        onClick: () => goToProfilePage('Avbryt'),
      }}
      nextButton={{
        onClick: handleNext,
      }}
    >
      <Stack spacing={1}>
        <SearchJobs />
        <EditJobtitlesSection />
      </Stack>
    </ProfileWizardStep>
  )
}
