import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { LocationInformation } from '@local/Types'
import usePickEpiContent from '@local/Utils/Hooks/usePickEpiContent'
import { generateErrorMessage } from '@local/Utils/Helpers/form.helpers'
import { CustomAutoComplete } from '@local/Components/CustomAutocomplete/CustomAutocomplete'
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Stack,
  TextField,
} from '@mui/material'
import { SearchRounded } from '@mui/icons-material'
import parse from 'html-react-parser'

import { JobLocationStepFormValues } from '../../Types/formTypes'
import {
  GetLocationsResponse,
  useLazyGetLocationsQuery,
} from '../../Api/locationsApi'
import {
  filterAlreadyPickedLocations,
  filterCountries,
} from '../../Utils/formHelper'

interface SearchLocationProps {
  label: string
  name: string
}

export const SearchLocations = ({ label, name }: SearchLocationProps) => {
  const [getLocations, { data: locationInformations, isFetching, isLoading }] =
    useLazyGetLocationsQuery()
  const { values, touched, errors, setFieldValue, setErrors } =
    useFormikContext<JobLocationStepFormValues>()

  const [input, setInput] = useState('')

  const { locationPicker } = usePickEpiContent().wizardVarOchHur

  const checkEquality = (
    option: LocationInformation,
    value: LocationInformation
  ) => option.name === value.name && option.id === value.id

  const handleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    _value: LocationInformation[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<LocationInformation>
  ) => {
    if (reason === 'removeOption') {
      removeLocation(details.option)
    } else if (reason === 'selectOption') {
      addLocation(details.option)
    } else if (reason === 'clear') {
      removeAllLocations()
    }
  }

  const removeAllLocations = () => {
    void setFieldValue(name, [])
  }

  const addLocation = (location: LocationInformation) => {
    void setFieldValue(name, [...values.locations, location], false)
  }

  const removeLocation = (location: LocationInformation) => {
    void setFieldValue(
      name,
      values.locations.filter((l) => l.id !== location.id)
    )
  }

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setErrors({ locations: [] })
    fetchDebounce(value)
    setInput(value)
  }

  const fetchDebounce = debounce((searchTerm: string) => {
    if (!searchTerm) return
    void getLocations(searchTerm)
  }, 300)

  const filteredLocations: LocationInformation[] = filterAlreadyPickedLocations(
    filterCountries(locationInformations),
    values.locations
  )

  return (
    <Stack spacing={1}>
      <CustomAutoComplete
        multiple
        value={values?.locations}
        options={!isFetching ? filteredLocations ?? [] : []}
        loading={isLoading || isFetching}
        getOptionLabel={(location: GetLocationsResponse) =>
          `${location.name}, ${locationPicker[location.type]}`
        }
        renderOption={(props, location: GetLocationsResponse) => (
          <li {...props} key={location.id} translate="no">
            {parse(location.nameWithHighLightedSearchTerm)}
            &nbsp;{locationPicker[location.type]}
          </li>
        )}
        inputValue={input}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        isOptionEqualToValue={checkEquality}
        open={input !== ''}
        popupIcon={<SearchRounded />}
        renderInput={(params) => (
          <TextField
            name="locations"
            error={
              errors.locations?.length > 0 && touched.locations !== undefined
            }
            helperText={generateErrorMessage({
              touched: touched.locations !== undefined,
              errorMsg: errors.locations as string,
            })}
            FormHelperTextProps={{ id: 'locations-input-error' }}
            {...params}
            placeholder={label}
          />
        )}
      />
    </Stack>
  )
}
