import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { SelectableChip } from '@local/Components/SelectableChip/SelectableChip'
import { ChipStack } from '@local/Components/ChipStack/ChipStack'

interface SuggestionBoxProps {
  title: string
  suggestions: string[]
  selected: string[]
  onClick: (suggestion: string) => void
}

export const SuggestionBox: React.FC<SuggestionBoxProps> = ({
  title,
  suggestions,
  selected,
  onClick,
}) => (
  <Card>
    <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }} />
    <CardContent>
      <ChipStack>
        {suggestions?.map((suggestion, i) => (
          <SelectableChip
            key={i}
            onClick={() => onClick(suggestion)}
            label={suggestion}
            isSelected={selected.includes(suggestion)}
          />
        ))}
      </ChipStack>
    </CardContent>
  </Card>
)
